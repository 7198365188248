var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "w99 f13 f-grey pt10" }, [_vm._v("圆角角度")]),
    _c("div", { staticClass: "p20" }, [
      _c(
        "div",
        {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "repeat(2, 1fr)",
            gap: "20px 0",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("div", { staticClass: "f-grey mr10" }, [_vm._v("左上：")]),
              _c("el-input-number", {
                attrs: { precision: 0, min: 0, max: 99 },
                model: {
                  value: _vm.mValue.borderTopLeftRadius,
                  callback: function ($$v) {
                    _vm.$set(_vm.mValue, "borderTopLeftRadius", $$v)
                  },
                  expression: "mValue.borderTopLeftRadius",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("div", { staticClass: "f-grey mr10" }, [_vm._v("右上：")]),
              _c("el-input-number", {
                attrs: { precision: 0, min: 0, max: 99 },
                model: {
                  value: _vm.mValue.borderTopRightRadius,
                  callback: function ($$v) {
                    _vm.$set(_vm.mValue, "borderTopRightRadius", $$v)
                  },
                  expression: "mValue.borderTopRightRadius",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("div", { staticClass: "f-grey mr10" }, [_vm._v("左下：")]),
              _c("el-input-number", {
                attrs: { precision: 0, min: 0, max: 99 },
                model: {
                  value: _vm.mValue.borderBottomLeftRadius,
                  callback: function ($$v) {
                    _vm.$set(_vm.mValue, "borderBottomLeftRadius", $$v)
                  },
                  expression: "mValue.borderBottomLeftRadius",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("div", { staticClass: "f-grey mr10" }, [_vm._v("右下：")]),
              _c("el-input-number", {
                attrs: { precision: 0, min: 0, max: 99 },
                model: {
                  value: _vm.mValue.borderBottomRightRadius,
                  callback: function ($$v) {
                    _vm.$set(_vm.mValue, "borderBottomRightRadius", $$v)
                  },
                  expression: "mValue.borderBottomRightRadius",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }