import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4cbac784&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=4cbac784&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_6m5rqjauvbyej62p7eiecas4wq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cbac784",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/天擎前端/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4cbac784')) {
      api.createRecord('4cbac784', component.options)
    } else {
      api.reload('4cbac784', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=4cbac784&scoped=true", function () {
      api.rerender('4cbac784', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/schema-template/components/SchemaArray/index.vue"
export default component.exports