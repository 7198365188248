// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./phone-head.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".ctn[data-v-2f9631be] {\n  position: relative;\n  width: 375px;\n  margin: 0 auto;\n  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);\n}\n.ctn .ctn-head[data-v-2f9631be] {\n  widows: 100%;\n  height: 64px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n}\n.ctn .ctn-body[data-v-2f9631be] {\n  min-height: 603px;\n  background: #fff;\n}\n.ctn .ctn-height-tag[data-v-2f9631be] {\n  position: absolute;\n  top: 650px;\n  left: -130px;\n  height: 17px;\n  width: 130px;\n  border-bottom: 1px solid #dedede;\n  color: #a2a2a2;\n  text-align: left;\n}\n.ctn .ctn-height-tag span[data-v-2f9631be] {\n  font-size: 12px;\n}\n", ""]);
// Exports
module.exports = exports;
