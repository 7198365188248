var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "controlPanel",
      staticClass: "control-panel",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          _vm.control.curWidget = null
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "phone-page",
          style: {
            height: _vm.iframeHeight + "px",
          },
        },
        [
          _c("iframe", {
            ref: "iframe",
            staticClass: "page-iframe",
            style: {
              height: _vm.iframeHeight + "px",
              pointerEvents: _vm.control.dragstatus ? "none" : "auto",
            },
            attrs: { frameborder: "no", src: _vm.iframeUrl },
            on: { load: _vm.load },
          }),
          _c(
            "div",
            {
              staticClass: "phone-page-player",
              style: {
                height: _vm.iframeHeight + "px",
                zIndex: _vm.control.dragstatus ? "20" : "1",
              },
              on: {
                dragover: _vm.layerMove,
                drop: _vm.drop,
                dragleave: _vm.dragleave,
              },
            },
            _vm._l(_vm.widgetInfoList, function (item) {
              return _c(
                "ControlModelShape",
                {
                  key: item.id,
                  attrs: { data: item },
                  on: { changeCurrWidget: _vm.changeCurrWidget },
                },
                [
                  _c("div", {
                    ref: "layerWidget",
                    refInFor: true,
                    staticClass: "page-layer-widget",
                    style: { height: item.height + "px" },
                    attrs: { type: "widget", id: item.id },
                  }),
                ]
              )
            }),
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "pageSetting",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              _vm.control.curWidget = null
            },
          },
        },
        [_c("i", { staticClass: "el-icon-setting" })]
      ),
      _vm.tabs.length
        ? _c(
            "div",
            {
              staticStyle: {
                position: "sticky",
                bottom: "0",
                width: "0",
                "z-index": "999",
              },
            },
            [
              _c("el-card", {
                staticStyle: {
                  width: "300px",
                  "background-color": "rgba(0, 0, 0, 0.7)",
                  color: "#fff",
                },
                attrs: { "body-style": "height: 300px; overflow-y: auto;" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [_c("div", [_vm._v("本页tab信息")])]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function () {
                        return _vm._l(_vm.tabs, function (tab) {
                          return _c("div", { key: tab.id }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "margin-bottom": "10px",
                                },
                              },
                              [_vm._v(" " + _vm._s(tab.title) + " ")]
                            ),
                            _c(
                              "div",
                              {
                                key: tab.id,
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "align-items": "center",
                                  "padding-left": "10px",
                                  "margin-bottom": "10px",
                                },
                              },
                              [
                                _c("span", [_vm._v(_vm._s(tab.id))]),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "background-color": "transparent",
                                      color: "#fff",
                                    },
                                    attrs: { type: "info", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.doCopy(tab.id)
                                      },
                                    },
                                  },
                                  [_vm._v("复制")]
                                ),
                              ],
                              1
                            ),
                          ])
                        })
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4216003457
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }