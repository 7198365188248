var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "课程详情页基本信息", visible: _vm.dialogVisible },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "dataForm", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "活动名称",
                prop: "name",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "360px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入活动名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "活动备注",
                prop: "remark",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "360px" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入活动备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }