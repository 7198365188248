var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "control-models" },
    _vm._l(_vm.filteredModelType, function (itemType, indexType) {
      return _c(
        "div",
        { key: indexType, staticClass: "wrap" },
        [
          _c("div", { staticClass: "wrap-label" }, [
            _c("span", [_vm._v(_vm._s(itemType))]),
          ]),
          [
            _c(
              "ul",
              { staticClass: "flex flex-wrap pt10 pb10" },
              _vm._l(_vm.$initializing, function (item, index) {
                return _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowModel(item, itemType),
                        expression: "isShowModel(item, itemType)",
                      },
                    ],
                    key: index,
                    staticClass: "item",
                    attrs: { "data-component": item.component, draggable: "" },
                    on: {
                      dragstart: _vm.handleDragStart,
                      dragend: _vm.dragEnd,
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "icon iconfont svg",
                      class: item.icon,
                    }),
                    _c("span", [_vm._v(_vm._s(item.name))]),
                  ]
                )
              }),
              0
            ),
          ],
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }