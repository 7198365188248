var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "configure-item",
        { attrs: { label: _vm.label } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.mValue.showDate,
                callback: function ($$v) {
                  _vm.$set(_vm.mValue, "showDate", $$v)
                },
                expression: "mValue.showDate",
              },
            },
            _vm._l(_vm.data, function (item, index) {
              return _c(
                "el-radio",
                { key: index, attrs: { label: item.value } },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "configure-item",
        { attrs: { label: "倒计时前文案" } },
        [
          _c("el-input", {
            attrs: { maxlength: 4 },
            model: {
              value: _vm.mValue.prefix,
              callback: function ($$v) {
                _vm.$set(_vm.mValue, "prefix", $$v)
              },
              expression: "mValue.prefix",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }