var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "configure-item",
    { attrs: { label: _vm.label } },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            action: "",
            accept: _vm.options.accept,
            "on-remove": _vm.handleRemove,
            "on-success": _vm.handleSuccess,
            "before-upload": _vm.beforeUpload,
            limit: 1,
            "http-request": _vm.customUpload,
            "file-list": _vm.fileList,
          },
        },
        [
          _vm.fileList.length === 0
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    plain: "",
                    icon: "el-icon-upload2",
                  },
                },
                [_vm._v("点击上传")]
              )
            : _vm._e(),
          _vm.tip
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [_vm._v(_vm._s(_vm.tip))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }