var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      attrs: { "label-width": "80px", "label-position": "left", size: "mini" },
    },
    [
      _vm._l(_vm.mValue, function (item) {
        return _c(
          "div",
          { staticClass: "panel" },
          [
            _c(
              "el-card",
              { attrs: { shadow: "always" } },
              [
                _c(
                  "el-collapse",
                  {
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  [
                    _c("el-collapse-item", {
                      staticClass: "wrap-title",
                      attrs: { name: item.id },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [_vm._v(_vm._s(item.label))]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticStyle: { "padding-left": "10px" } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "标题" } },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "请输入标题" },
                                          model: {
                                            value: item.title,
                                            callback: function ($$v) {
                                              _vm.$set(item, "title", $$v)
                                            },
                                            expression: "item.title",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "定位位置" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            model: {
                                              value: item.position,
                                              callback: function ($$v) {
                                                _vm.$set(item, "position", $$v)
                                              },
                                              expression: "item.position",
                                            },
                                          },
                                          _vm._l(
                                            _vm.positions,
                                            function (option) {
                                              return _c("el-option", {
                                                key:
                                                  option.label + option.value,
                                                attrs: {
                                                  label: option.label,
                                                  value: option.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "未选中样式" } },
                                      [
                                        _c("ImgpondMy", {
                                          attrs: { count: 1 },
                                          model: {
                                            value: item.inactiveBgImg,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "inactiveBgImg",
                                                $$v
                                              )
                                            },
                                            expression: "item.inactiveBgImg",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "选中样式" } },
                                      [
                                        _c("ImgpondMy", {
                                          attrs: { count: 1 },
                                          model: {
                                            value: item.activeBgImg,
                                            callback: function ($$v) {
                                              _vm.$set(item, "activeBgImg", $$v)
                                            },
                                            expression: "item.activeBgImg",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "特色标签" } },
                                      [
                                        _c("ImgpondMy", {
                                          attrs: { count: 1 },
                                          model: {
                                            value: item.prefixIcon,
                                            callback: function ($$v) {
                                              _vm.$set(item, "prefixIcon", $$v)
                                            },
                                            expression: "item.prefixIcon",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("i", {
              staticClass: "el-icon-circle-close deleteBtn",
              on: {
                click: function ($event) {
                  return _vm.delItem(item.id)
                },
              },
            }),
          ],
          1
        )
      }),
      _c(
        "el-button",
        {
          staticStyle: { width: "100%" },
          attrs: { plain: "", type: "primary" },
          on: { click: _vm.addItem },
        },
        [_vm._v("添加锚点按钮")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }