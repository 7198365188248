var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("SearchForm", {
        on: { search: _vm.fetchData },
        model: {
          value: _vm.queryParams,
          callback: function ($$v) {
            _vm.queryParams = $$v
          },
          expression: "queryParams",
        },
      }),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: ["course.add_course"],
              expression: "['course.add_course']",
            },
          ],
          staticStyle: { "margin-bottom": "10px" },
          attrs: { type: "primary", icon: "el-icon-plus" },
          on: {
            click: function ($event) {
              return _vm.$refs.edit.handleEdit("create")
            },
          },
        },
        [_vm._v("创建")]
      ),
      _c(
        "el-card",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.body",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                  modifiers: { body: true },
                },
              ],
              attrs: {
                data: _vm.tableData,
                "element-loading-text": "加载中 ...",
                border: "",
                "header-align": "center",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "活动ID",
                  "min-width": "80px",
                  prop: "activity_id",
                  align: "center",
                  "header-align": "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "活动名称",
                  "min-width": "200",
                  prop: "name",
                  align: "center",
                  "header-align": "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "是否有效",
                  "min-width": "80px",
                  prop: "has_enable",
                  align: "center",
                  "header-align": "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.has_enable
                          ? _c("span", { staticClass: "warn" }, [
                              _vm._v("有效"),
                            ])
                          : _c("span", { staticClass: "info" }, [
                              _vm._v("无效"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "链接",
                  "min-width": "300",
                  prop: "link",
                  "header-align": "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          { attrs: { href: scope.row.link, target: "_blank" } },
                          [_vm._v(_vm._s(scope.row.link))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  "min-width": "80px",
                  prop: "creator.name",
                  align: "center",
                  "header-align": "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "310",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "300px",
                  align: "center",
                  "header-align": "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["zt.design_zt"],
                                expression: "['zt.design_zt']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.goEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("页面配置")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["course.modify_course"],
                                expression: "['course.modify_course']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.edit.handleEdit(
                                  "edit",
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["ca.shelf_ca"],
                                expression: "['ca.shelf_ca']",
                              },
                            ],
                            staticStyle: { margin: "0 10px" },
                            attrs: {
                              title: `确定要${
                                scope.row.has_enable ? "下架" : "上架"
                              }该专题页吗？`,
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.handleDisplay(scope.row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "small",
                                },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.has_enable ? "下架" : "上架"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["ca.log_ca"],
                                expression: "['ca.log_ca']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.operationLog.showLogs(
                                  scope.row.uuid
                                )
                              },
                            },
                          },
                          [_vm._v("日志")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["course.copy_course"],
                                expression: "['course.copy_course']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.edit.handleEdit(
                                  "copy",
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("复制")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("pagination", {
                attrs: {
                  total: _vm.count,
                  page: _vm.queryParams.page,
                  limit: _vm.queryParams.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "size", $event)
                  },
                  pagination: _vm.fetchData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("edit", { ref: "edit" }),
      _c("operation-log", { ref: "operationLog" }),
      _c("PrizeStatistical", { ref: "prizeStatistical" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }