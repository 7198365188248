var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("configure-item", { attrs: { label: _vm.label } }, [
    _c(
      "div",
      { staticClass: "wrap" },
      [
        _c(
          "el-checkbox-group",
          {
            model: {
              value: _vm.mValue,
              callback: function ($$v) {
                _vm.mValue = $$v
              },
              expression: "mValue",
            },
          },
          _vm._l(_vm.optionList, function (item) {
            return _c(
              "el-checkbox",
              { key: item.value, attrs: { label: item.value } },
              [_vm._v(_vm._s(item.label))]
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }